import ScrollView from "components/ScrollView"
import Tile from "components/Tiles/Tile"
import moment from "moment"
import BirthdayList from "Persons/BirthdayList"
import { usePersons } from "Persons/PersonApi"
import { useMemo } from "react"


export default function UpcomingBirthdaysTile({...props}) {
    const personParams = useMemo(() => ({
        with_upcoming_birthday: true,
        start_date: moment().format('YYYY-MM-DD'),
        end_date: moment().format('YYYY-MM-DD'),
    }), [])

    const { data, isPending } = usePersons(personParams)

    const listData = useMemo(() => {
        return data?.sort((a,b) => {
            return moment(b.birthday).year(0).isBefore(moment(a.birthday).year(0)) ? 1 : -1
        })
    }, [data])

    if (listData?.length === 0) {
        return null
    }

    return (
        <Tile title='Geburtstage'>
            <ScrollView maxHeight='20em'>
                <BirthdayList persons={listData} isPending={isPending} />
            </ScrollView>
        </Tile>
    )
}
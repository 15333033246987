import { Outlet } from "react-router-dom";
import Sidebar from "./Sidebar/Sidebar";
import styled, {ThemeProvider} from 'styled-components';
import Theme from "Theming/Theme";

const Container = styled.div`
    display: flex;
    flex-direction: row;
    background-color: #e7e7e7;
    height: 100vh;
`

const OutletContainer = styled.div`
    overflow: hidden;
    height: 100%;
    width: 100%;
    display: flex;
`


export default function App() {
    return (
        <ThemeProvider theme={Theme}>
            <Container>
                <Sidebar/>
                <OutletContainer>
                    <Outlet/>
                </OutletContainer>
            </Container>
        </ThemeProvider>
    )
}
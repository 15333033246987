import { useMemo } from 'react'
import styled from 'styled-components'

const Container = styled.div`
    cursor: ${props => props.clickable ? 'pointer' : 'inherit'};
    user-select: ${props => props.clickable ? 'none' : 'inherit'};
    pointer-events: ${props => props.clickable ? 'auto' : 'none'};
    opacity: ${props => props.enabled ? 1.0 : 0.35};
    transition: 0.25s;

    &:hover {
        ${props => {
            return props.clickable ? `
                opacity: 0.5;
            `
            :
            ''
        }}
    }
   
 
`

interface ClickableOpacityProps {
    children?: React.ReactNode,
    enabled?: boolean,
    onClick?: () => any
}

const ClickableOpacity = ({children, enabled=true, onClick=undefined, ...props}:ClickableOpacityProps) => {

    const clickable = useMemo(() => {
        return onClick && enabled
    }, [enabled, onClick])

    return (
        <Container clickable={clickable} enabled={enabled} onClick={onClick} {...props}>
            { children }
        </Container>
    )
}

export default ClickableOpacity
export type { ClickableOpacityProps }
import ListItem from "components/ListView/BetterListItem"
import { useMemo } from "react"
import EventDemandTag from "./EventDemandTag"
import EventUtility from "./EventUtility"
import { usePerson } from "Persons/PersonApi"
import Event from "./Event"
import Theme from "Theming/Theme"
import { TagProps } from "Tags/Tag"


export interface EventListItemProps {
    event:Event,
    periodFormat?:string,
    hideEmployee?:boolean,
    hideCustomer?:boolean
}

const EventListItem = ({ event, hideEmployee = false, hideCustomer = false, periodFormat = 'DD.MM.YYYY HH:mm', ...props }) => {

    const employeeId = useMemo(() => {
        return event?.employee_id || event?.creator_id
    }, [event])

    const { data: customer } = usePerson(event?.customer_id)
    const { data: employee } = usePerson(employeeId)

    const periodString = useMemo(() => {
        return EventUtility.getPeriodLabel(event, periodFormat)
    }, [event, periodFormat])

    const tags:TagProps[] = useMemo(() => {
        const result = []

        if(event.confirmed === 1) {
            result.push({
                text: "Bestätigt",
                backgroundColor: 'success',
                color: 'white'
            })
        }
        else if(event.confirmed === 0) {
            result.push({
                text: "Abgesagt"
            })
        }
        else if (!EventUtility.hasEmployee(event)) {
            result.push(EventDemandTag(event))
        }
        else if (EventUtility.isSubstituted(event)) {
            result.push({
                text: "Vertretung"
            })
        }

        return result
    }, [event])

    const title = useMemo(() => {
        const segments = []
        if (!hideCustomer && customer) {
            segments.push(`${customer?.first_name} ${customer?.last_name}`)
        }
        if (!hideEmployee && employee) {
            segments.push(`${employee?.first_name} ${employee?.last_name}`)
        }
        if(hideEmployee && hideCustomer) {
            segments.push(event.title)
        }
        return segments.join(' - ')
    }, [customer, hideCustomer, employee, hideEmployee, event])

    return (
        <ListItem 
            tags={tags} 
            title={title}
            subtitle={periodString}
            {...props}
        />
    )
}

export default EventListItem
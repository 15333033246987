
import styled from 'styled-components';
import SidebarHeader from './SidebarHeader';
import SidebarItem from './SidebarItem';
import { useNavigate } from 'react-router-dom';
import SidebarNavItem from './SidebarNavItem';

const Container = styled.div`
    background-color: #fff;
    box-shadow: 0px 1px 4px rgba(0, 0, 0, 0.2);
    display: flex;
    flex-direction: column;
`

const NavigationItems = styled.div`
    flex-grow: 1;
    display: flex;
    flex-direction: column;
`

const Footer = styled.div`
    padding: ${props => props.theme.spacing.vMedium} 0px;
    border-top: 1px solid ${props => props.theme.colors.border };
`

export default function Sidebar({...props}) {

    const navigate = useNavigate()

    return (
        <Container {...props}>
            <SidebarHeader/>
            <NavigationItems>
                <SidebarNavItem to='' title='Home' icon='fa/FaHome'/>
                <SidebarNavItem to='Finanzen' title='Finanzen' icon='fa/FaMoneyBillWave' />
                <SidebarNavItem to='Personen' title='Personen' icon='fa/FaUsers' />
                <SidebarNavItem to='Datenbank' enabled={false} title='Datenbank' icon='fa/FaDatabase' />
            </NavigationItems>
            <Footer>
                <SidebarItem title='Ausloggen' icon='fa/FaPowerOff' onClick={() => navigate('..')} />
            </Footer>
        </Container>
    )
}
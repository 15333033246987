import ClickableOpacity from 'components/Decorator/ClickableOpacity'
import Icon from 'components/Icons/Icon'
import { Text } from 'components/labels/Label'
import Padding from 'components/Padding'
import Row from 'components/Row'
import styled from 'styled-components'

const Container = styled.div`
    user-select: none;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    align-items: stretch;
    background-color: ${props => props.backgroundColor};
`

const Banner = styled.div`
    width: 5px;
    background-color: ${props => props.color};
`

export type SidebarItemProps =  {
    title: string,
    icon: string,
    enabled?: boolean,
    onClick?: () => any,
    bannerColor?: string,
    backgroundColor?:string
}

export default function SidebarItem({ 
    title, 
    icon, 
    onClick, 
    enabled=true, 
    bannerColor='transparent',
    backgroundColor='transparent'
}:SidebarItemProps) {
    return (
        <ClickableOpacity enabled={enabled} onClick={onClick}>
            <Container backgroundColor={backgroundColor}>
                <Padding>
                    <Row gap='medium' vCentered>
                        <Icon size='20px' path={icon}/>
                        <Text>{title}</Text>
                    </Row>
                </Padding>
                <Banner color={bannerColor}/>
            </Container>
        </ClickableOpacity>
    )
}
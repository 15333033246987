import { LeadingText, Text } from "components/labels/Label"
import ListView from "components/ListView/ListView"
import Row from "components/Row";
import moment from "moment";
import Person from "./Person";
import usePersonUtility from "./usePersonUtility";
import Padding from "components/Padding";
import PersonTag from "./PersonLink";


interface Props {
    persons: Person[],
    isPending: boolean
}

const BirthdayList = ({ persons, isPending}: Props) => {

    const { getAge } = usePersonUtility()

    const listItem = (person:Person) => {
        const today = moment().format('DD.MM') === moment(person.birthday).format('DD.MM') 
        const dateString = today ? "Heute" : moment(person.birthday).format('DD.MM');
        const age = getAge(person)
        const label = today ? ` ist ${age} geworden` : ` wird ${age + 1}`
        return (
                <Padding>
                    <Row gap='medium' vCentered>
                        <LeadingText>{dateString}</LeadingText>
                        <Row gap='small' vCentered>
                            <PersonTag id={person.id}/>
                            <Text>{label}</Text>
                        </Row>
                    </Row>
                </Padding>
        )
    }

    return (
        <ListView 
            isLoading={isPending} 
            data={persons} 
            listItem={listItem}
            
        />
    )
}

export default BirthdayList
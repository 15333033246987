import { useQuery } from "@tanstack/react-query";
import useApiData from "Api/useApiData";
import { useApiContext } from "Common/Api/ApiContext";

const useVacationBalance = (params) => {

    const { axiosInstance } = useApiContext()

    return useQuery({
        queryKey: ['vacation_balance', params],
        queryFn: () => axiosInstance.get('vacation_bookings/balance', {params}).then(res => res.data)
    })
}

export default useVacationBalance
import styled from 'styled-components'

const ScrollView = styled.div`
    display: flex;
    flex-direction: column;
    min-height: 0;
    flex-shrink: 1;
    overflow-y: auto;
    overflow-x: clip;
    direction: ${props => props.direction || 'ltr'};
    max-height: ${props => props.maxHeight};
`

export default ScrollView
import Theme from "Theming/Theme"


const EventDemandTag = (event) => {

    if (!event) {
        return null
    }

    var demand = event.demand

    if (demand === null) {
        return {
            text: "Vertretungsbedarf unklar",
            backgroundColor: Theme.dangerColor
        }
    }

    if (demand <= 0) {
        return {
            text: "Kein Vertretungsbedarf",
            backgroundColor: Theme.successColor
        }
    }

    if (demand <= 50) {
        return {
            text: "Vertretung erwünscht",
            backgroundColor: Theme.warningColor
        }
    }

    return {
        text: "Vertretungsbedarf",
        backgroundColor: Theme.dangerColor
    }
}

export default EventDemandTag
import ModelListTile from "Models/ModelTile"
import { useMemo } from "react"
import { IModelInstanceModalOptions } from "Models/ModelInstanceModal"
import ListItem from "components/ListView/BetterListItem"
import EmployeeService from "Common/EmployeeServices/EmployeeService"
import { useEmployeeServices } from "Common/EmployeeServices/EmployeeServiceApi"
import EmployeeServiceModel from "Common/EmployeeServices/EmployeeServiceModel"
import { TagProps } from "Tags/Tag"

type EmployeeServiceTileProps = {
    employeeId: number
}

const modalOptions: IModelInstanceModalOptions = {
    formOptions: {
        hidden: ['id', 'updatedAt', 'createdAt', 'serviceId']
    }
}

export default function EmployeeServiceTile({
    employeeId
}:EmployeeServiceTileProps) {


    const initialData = useMemo(() => {
        return {
            employeeId
        }
    }, [employeeId])

    const serviceParams = useMemo(() => {
        return {
            employeeId
        }
    }, [employeeId])

    const services = useEmployeeServices(serviceParams)

    return (
        <ModelListTile<EmployeeService>
            model={EmployeeServiceModel}
            data={services.data}
            initialData={initialData}
            modalOptions={modalOptions}
            listItem={(service, options) => {
                const tags:TagProps[] = []

                return (
                    <ListItem
                        {...options}
                        title={service.service?.label}
                        tags={tags}
                    />
                )
            }}
        />
    )
}
import DatePicker from "components/input/DatePicker";
import AbsenceTypeSelector from "./AbsenceTypeSelector";
import { FormGroup } from "components/form/FormComponents";
import Row from "components/Row";
import InputLabel from "components/input/InputLabel";
import EmployeeSelect from "Employees/EmployeeSelect";
import TextArea from "components/input/TextArea";
import Checkbox from "components/checkbox/Checkbox";
import IForm from "Forms/IForm";

interface Props {
    form: IForm
}

export default function AbsenceForm({ form }:Props) {
    
    return(
        <div>
            <FormGroup>
                <EmployeeSelect
                    label='Mitarbeiter'
                    name='employee_id'
                    onChange={({ value }) => form.setFieldValue('employee_id', value)}
                    value={form.data.employee_id}>
                </EmployeeSelect>
                <Row>
                    <DatePicker
                        grow
                        label='Beginn'
                        name='begin_date'
                        onChange={(value) => form.setFieldValue('begin_date', value)}
                        value={form.data.begin_date}
                    />
                    <InputLabel>-</InputLabel>
                    <DatePicker
                        grow
                        label='Ende'
                        name='end_date'
                        onChange={(value) => form.setFieldValue('end_date', value)}
                        value={form.data.end_date}
                    />
                </Row>           
                <AbsenceTypeSelector
                    label='Grund'
                    name='type_id'
                    onChange={({ value }) => form.setFieldValue('type_id', value)}
                    value={form.data.type_id}
                />
                <DatePicker
                    label='Antragsdatum'
                    name='request_date'
                    onChange={(value) => form.setFieldValue('request_date', value)}
                    value={form.data.request_date}
                />
            </FormGroup>
            <FormGroup>
                <Checkbox
                    label='Bezahlt'
                    name='paid'
                    value={form.data.paid}
                    onChange={({ value }) => form.setFieldValue('paid', value)}
                /> 
            </FormGroup>
            <FormGroup>
                <TextArea
                    label='Beschreibung'
                    optional
                    minRows={6}
                    name='details'
                    value={form.data.details}
                    onChange={e => form.setFieldValue('details', e.target.value)}
                />
            </FormGroup>
        </div>
    )
}
import { useForm } from "components/form/form";
import SubmitButton from "Forms/SubmitButton";
import Modal, { ModalFooter } from "components/modal/Modal";
import Spacer from "components/Spacer/Spacer";
import { useCallback, useEffect } from "react";
import ContactPersonForm from "./ContactPersonForm";
import ameli from "Api/ameli";
import PhoneListControl from "PhoneNumbers/PhoneListControl";
import { FormGroup } from "components/form/FormComponents";
import ConditionalView from "components/Decorator/ConditionalView";

export default function ContactPersonModal({
    visible,
    setVisible,
    selected,
    dispatchAction
}) {

    const {
        formData,
        setFormData,
        formDataChanged,
        formDataValid,
        handleFormChange,
        setFormField
    } = useForm(selected)

    const reloadPhoneNumbers = useCallback(async (contactDetailsId) => {
        if (contactDetailsId === undefined) {
            setFormField('phone_numbers', [])
            return
        }
        const phoneNumbers = await ameli.get(`/phone_numbers`, { params: { contact_details_id: contactDetailsId } })
        setFormField('phone_numbers', phoneNumbers.data)
    }, [setFormField])

    useEffect(() => {
        if (visible) {
            setFormData(selected)
            reloadPhoneNumbers(selected.contact_details_id)
        }
    }, [setFormData, selected, visible, reloadPhoneNumbers])
    
    const isUpdate = formData && 'id' in formData;

    return (
        <Modal
            minWidth={"400px"}
            visible={visible}
            setVisible={setVisible}
            title={"Kontaktperson"}>
            <Spacer vLarge hMedium>
                <ContactPersonForm
                    formData={formData}
                    handleFormChange={handleFormChange}
                    setFormField={setFormField}
                />
                <ConditionalView condition={isUpdate}>
                    <FormGroup label='Telefonnummern'>
                        <PhoneListControl contactDetailsId={selected.contact_details_id}/>
                    </FormGroup>
                </ConditionalView>
                <ModalFooter>
                    <SubmitButton
                        visible={isUpdate}
                        disabled={!formDataChanged || !formDataValid}
                        onClick={() => dispatchAction('update', formData).then(() => setVisible(false))}>
                        Übernehmen
                    </SubmitButton>
                    <SubmitButton
                        visible={!isUpdate}
                        disabled={!formDataValid}
                        onClick={() => dispatchAction('create', formData).then(() => setVisible(false))}>
                        Erfassen
                    </SubmitButton>
                    <SubmitButton
                        visible={isUpdate}
                        onClick={() => dispatchAction('delete', formData.id).then(() => setVisible(false))}>
                        Löschen
                    </SubmitButton>
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}
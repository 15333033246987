import Tile from "components/Tiles/Tile";
import { useContext, useMemo } from "react";
import { useAbsences } from "./AbsenceApi";
import moment from "moment";
import ListView from "components/ListView/ListView";
import ClickableOpacity from "components/Decorator/ClickableOpacity";
import Padding from "components/Padding";
import Row from "components/Row";
import { LeadingText } from "components/labels/Label";
import { formatPeriodString } from "Time/timeUtility";
import styled from 'styled-components';
import PersonTag from "Persons/PersonLink";
import { ModalContext } from "components/modal/ModalContext";
import TagContainer from "Tags/TagContainer";
import AbsenceTypeTag from "AbsenceTypes/AbsenceTypeTag";

const LeadingTextContainer = styled.div`
    min-width: 5.5em;
    max-width: 5.5em;
`

export default function UpcomingAbsencesTile({...props}) {

    const { absenceModal } = useContext(ModalContext)

    const params = useMemo(() => ({
        start: moment().format('YYYY-MM-DD'),
        end: null
    }), [])

    const absences = useAbsences(params)

    const listData = useMemo(() => {
        return absences.data?.sort((a, b) => { return new Date(a.begin_date).valueOf() - new Date(b.begin_date).valueOf() })
    }, [absences.data])

    if(listData?.length === 0) {
        return null
    }

    return (
        <Tile title="Abwesenheiten" {...props}>
            <ListView
                data={listData}
                Separator={() => null}
                listItem={(absence) => (
                    <ClickableOpacity onClick={() => absenceModal.select(absence)}>
                        <Padding>
                            <Row hGap='medium'>
                                <LeadingTextContainer>
                                    <LeadingText>{ formatPeriodString(absence.begin_date, absence.end_date, '', 'DD.MM')}</LeadingText>
                                </LeadingTextContainer>
                                <TagContainer>
                                    <PersonTag id={absence.employee_id}/>
                                    <AbsenceTypeTag absenceType={absence.type}/>
                                </TagContainer>
                            </Row>
                        </Padding>
                    </ClickableOpacity>
                )}
            />

        </Tile>
    )
}
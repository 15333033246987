import { Text } from 'components/labels/Label';
import styled from 'styled-components';
import logo from './../../assets/logo.png'
import Column from 'components/Column';
import Row from 'components/Row';
import Padding from 'components/Padding';
import Theme from 'Theming/Theme';

const Container = styled.div`
    border-bottom: 1px solid ${props => props.theme.colors.border};
    user-select: none;
`

const LogoContainer = styled.div`
    display: flex;
    justify-content: center;
    width: 40px;
    height: 40px;
    position: relative;
`

const Logo = styled.img`
    position: absolute;
    width: 50px;
    height: 50px;
    left: -8px;
    top: -6px;
`

export default function SidebarHeader() {
    return (
        <Container>
            <Padding vertical='large' horizontal='large'>
                <Row gap='8px'>
                    <LogoContainer>
                        <Logo src={logo} alt={""} />
                    </LogoContainer>
                    <Column>
                        <Text size='large' bold>Mit Herz und Hand</Text>
                        <Text color={Theme.colors.subtleText}>Abrechnungssystem</Text>
                    </Column>
                </Row>
            </Padding>
        </Container>
    )
}
import { Tag, TagProps } from "Tags/Tag"
import AbsenceType from "./AbsenceType"
import { useMemo } from "react"

type AbsenceTypeTagProps = {
    absenceType: AbsenceType
}

export default function AbsenceTypeTag({absenceType}:AbsenceTypeTagProps) {

    const props: TagProps = useMemo(() => {
        if(!absenceType) {
            return {
                text: "??"
            }
        }
        var result:TagProps = {
            text: absenceType.label
        }
        if (absenceType.id === 1) {
            result.backgroundColor = 'danger',
                result.color = 'white'
        }
        if(absenceType.id === 2) {
            result.backgroundColor = 'success',
            result.color = 'white'
        }

        return result

    }, [absenceType])

    if(!absenceType) {
        return null
    }

    return (
        <Tag {...props}/>
    )
}
import styled from 'styled-components'

type RowProps = {
    alignment?: string,
    vCentered?: boolean,
    hCentered?: boolean,
    grow?: boolean,
    gap?: string,
    hGap?: string,
    vGap?: string
}

const Row = styled.div<RowProps>`
    display: flex;
    flex-direction: row;
    align-items: ${props => props.alignment ? props.alignment : props.vCentered ? 'center' : 'flex-start'};
    justify-content: ${props => props.hCentered ? 'center' : 'flex-start'};
    flex-grow: ${props => props.grow ? '1' : '0'};
    column-gap: ${props => {
        const size = props.hGap || props.gap
        return props.theme.getHorizontalSpacing(size)
    }};
    row-gap: ${props => {
        const size = props.vGap || props.gap
        return props.theme.getVerticalSpacing(size)
    }}
`
export default Row
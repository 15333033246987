import styled from 'styled-components'


type ColumnProps = {
    alignment?: string,
    vCentered?: boolean,
    hCentered?: boolean,
    grow?: boolean,
    gap?: string,
    hGap?: string,
    vGap?: string
}

const Column = styled.div<ColumnProps>`
    display: flex;
    flex-direction: column;
    flex-grow: ${props => props.grow ? '1' : '0'};
    min-width: ${props => props.minWidth || 'initial'};
    max-width: ${props => props.maxWidth || 'initial'};
    width: ${props => props.width || 'initial'};
    justify-content: ${props => props.vCentered ? 'center' : 'initial'};
    align-self: ${props => props.vCentered ? 'stretch' : 'initial'};
    align-items: ${props => props.hCentered ? 'center' : 'initial'};
    column-gap: ${props => {
        const size = props.hGap || props.gap
        return props.theme.getHorizontalSpacing(size)
    }};
    row-gap: ${props => {
        const size = props.vGap || props.gap
        return props.theme.getVerticalSpacing(size)
    }}
`

export default Column
import { Text } from "components/labels/Label"
import { usePerson } from "./PersonApi"
import styled from 'styled-components'
import { useMemo } from "react"
import Padding from "components/Padding"
import ClickableOpacity from "components/Decorator/ClickableOpacity"
import { Tag } from "Tags/Tag"

const Container = styled.div`
    border-radius: 8px;
    background-color: #dadada;
`

type PersonTagProps = {
    id:number
}

export default function PersonTag({id}:PersonTagProps) {

    const { data } = usePerson(id)

    const label = useMemo(() => {
        if(data) {
            return `${data.first_name} ${data.last_name}`
        } 
    }, [data])

    return (
        <ClickableOpacity onClick={() => console.log("Helloo")}>
            <Tag text={label}/>
        </ClickableOpacity>
    )
}

import BankInformation from "./BankInformation";
import Tile from "components/Tiles/Tile";
import { FaPen } from "react-icons/fa";
import Spacer from "components/Spacer/Spacer";
import Label from "components/labels/Label";
import TileSection from "components/TileSection";
import useModal from "components/modal/useModal";
import BankInformationModal from "./BankInformationModal";

interface Props {
    bankInformation:BankInformation
}

export default function BankInformationTile({ bankInformation}:Props) {

    const modal = useModal<BankInformation>()

    if(!bankInformation) {
        return null
    }

    return (
        <>
            <BankInformationModal {...modal}/>
            <Tile
                title='Bankverbindung'
                actions={[
                    {
                        icon: 'fa/FaPen',
                        execute: () => modal.select(bankInformation)
                    }

                ]}
            >
                <Spacer medium>
                    <TileSection label='Bank'>
                        <Label>{bankInformation?.bank_name || '-'}</Label>
                    </TileSection>
                    <TileSection label='Iban'>
                        <Label>{bankInformation?.iban || '-'}</Label>
                    </TileSection>
                </Spacer>
            </Tile>
        </>
    )
}
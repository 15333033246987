import { useMemo, useState } from "react"
import useOperationReportEntries from "./useOperationReportEntries"
import useYearMonth from "components/Time/useYearMonth"
import OperationReportEntry from "./Entries/OperationReportEntry"
import moment from "moment"
import EventUtility from "Events/EventUtility"
import { useEvents } from "Events/EventApi"



const useOperationReport = (employeeId, year, month, entryData, ...props) => {
    const [id, setId] = useState(props.id)
    const yearMonth = useYearMonth(year, month)
    const entries = useOperationReportEntries(entryData)

    const isValid = useMemo(() => {
        return entries.isValid && Boolean(employeeId) && Boolean(year) && Boolean(month)
    }, [entries.isValid, employeeId, year, month])

    const toDatabase = () => {
        return {
            id: id,
            employeeId: employeeId,
            year: year,
            month: month,
            entries: entries.data.map(OperationReportEntry.toDatabase)
        }
    }

    return {
        employeeId,
        yearMonth,
        entries,
        isValid,
        id,
        setId,
        toDatabase
    }
}

const useGeneratedOperationReport = (employeeId, year, month) => {

    const getPeriod = (year, month) => {
        if (year && month) {
            return ([
                moment().year(year).month(month - 1).startOf('month').startOf('day').format('YYYY-MM-DD HH:mm'),
                moment().year(year).month(month - 1).endOf('month').endOf('day').format('YYYY-MM-DD HH:mm')
            ])
        }
        else if (year) {
            return ([
                moment().year(year).startOf('year').startOf('day').format('YYYY-MM-DD HH:mm'),
                moment().year(year).endOf('year').endOf('day').format('YYYY-MM-DD HH:mm')
            ])
        }
        return [undefined, undefined]
    }

    const params = useMemo(() => {
        const [periodStart, periodEnd] = getPeriod(year, month)

        return {
            periodStart,
            periodEnd,
            employee_id: employeeId
        }
    }, [employeeId, year, month])

    const events = useEvents(params)

    const entries = useMemo(() => {
        return events.data
                    ?.filter(event => EventUtility.isOperation(event) && event.confirmed)
                    ?.map(OperationReportEntry.fromEvent)  
    }, [events.data])

    return useOperationReport(employeeId, year, month, entries)
}

export {
    useOperationReport,
    useGeneratedOperationReport
}
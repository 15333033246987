import ScrollView from "components/ScrollView"
import Spacer from "components/Spacer/Spacer"
import Tile from "components/Tiles/Tile"
import Table from "components/table/table"
import useVacationRequestColumns from "./useVacationRequestColumns"
import { useContext, useMemo } from "react"
import { ModalContext } from "components/modal/ModalContext"
import { useVacationRequests } from "Vacations/VacationApi"

const VacationRequestScreen = () => {

    const params = useMemo(() => ({

    }), [])

    const columns = useVacationRequestColumns()
    const vacationRequests = useVacationRequests(params)
    const { vacationRequestModal } = useContext(ModalContext)

    return (
        <ScrollView>
            <Spacer medium>
                <Tile
                    title='Urlaubsanträge'
                >       
                    <Table
                        columns={columns}
                        data={vacationRequests?.data}
                        onClick={vacationRequestModal.select}
                    />
                </Tile>
            </Spacer>
        </ScrollView>
    )
}

export default VacationRequestScreen
import Modal, { IModalProps, ModalFooter } from "components/modal/Modal";
import SubmitButton from "Forms/SubmitButton";
import Spacer from "components/Spacer/Spacer";
import useVacationRequestForm from "./useVacationRequestForm";
import VacationRequestForm from "./VacationRequestForm";
import Row from "components/Row";
import Column from "components/Column";
import Button from "components/Button";
import TextArea from "components/input/TextArea";
import VacationBookingPreview from "Vacations/Bookings/VacationBookingPreview";
import ConditionalView from "components/Decorator/ConditionalView";
import VacationRequest from "./VacationRequest";
import { useVacationRequestCreate, useVacationRequestDelete, useVacationRequestUpdate } from "Vacations/VacationApi";
import { useAbsenceCreate } from "Absences/AbsenceApi";
import { useMemo } from "react";
import useVacationBookingPreview from "Vacations/useVacationBookingPreview";

interface Props extends IModalProps<VacationRequest> {}

export default function VacationRequestModal({
    selected: vacationRequest,
    visible,
    setVisible,
    ...props
}:Props) {

    const isUpdate = 'id' in vacationRequest
    const title = isUpdate ? "Urlaubsantrag bearbeiten" : "Urlaubsantrag erstellen"
    const form = useVacationRequestForm(vacationRequest)
    const createAbsence = useAbsenceCreate()
    const createVacationRequest = useVacationRequestCreate()
    const updateVacationRequest = useVacationRequestUpdate()
    const deleteVacationRequest = useVacationRequestDelete()

    const bookingParams = useMemo(() => ({
        employeeId: form.data.employee_id,
        periodStart:form.data.start_date,
        periodEnd:form.data.end_date,
        requestDate: form.data.request_date
    }), [form.data])

    const bookingPreview = useVacationBookingPreview(bookingParams)

    const mutateOptions = {
        onSettled: () => {
            setVisible(false)
        }
    }

    const reject = () => {
        updateVacationRequest.mutate({ ...form.data, rejected: true }, mutateOptions)
    }

    const handleCreate = () => {
        createVacationRequest.mutate(form.data, mutateOptions)
    }

    const handleUpdate = () => {
        updateVacationRequest.mutate(form.data, mutateOptions)
    }

    const handleDelete = () => {
        deleteVacationRequest.mutate(form.data.id, mutateOptions)
    }

    const accept = () => {
        createAbsence.mutate(
            {
                employee_id: form.data.employee_id,
                begin_date: form.data.start_date,
                end_date: form.data.end_date,
                paid: form.data.paid,
                request_date: form.data.request_date,
                type_id: 2
            },
            {
                onSuccess: () => {
                    handleDelete()
                }
            }
        )
    }

    return (
        <Modal
            selected={vacationRequest}
            visible={visible}
            setVisible={setVisible}
            title={title}
            {...props}
        >
            <Spacer medium>
                <Row gap='1em'>
                    <Column grow>
                        <VacationRequestForm form={form}/>
                    </Column>
                    <ConditionalView condition={form.data.paid}>
                        <Column gap='0.5em' width='20em'>
                            <TextArea
                                minRows={3}
                                optional
                                label='Begründung'
                                value={form.getFieldValue('rejection_reason')}
                                onChange={e => form.setFieldValue('rejection_reason', e.target.value)}
                            />
                            <div>
                                <Button onClick={reject}>Ablehnen</Button>
                            </div>
                            <VacationBookingPreview preview={bookingPreview.data}/>
                        </Column>
                    </ConditionalView>
                </Row>
                <ModalFooter>
                    {
                        isUpdate ?
                            <>
                                <SubmitButton
                                    disabled={!form.fieldsValid || !(!form.data.paid || bookingPreview?.data?.unbookedDays === 0)}
                                    onClick={accept}
                                >
                                    Annehmen
                                </SubmitButton>
                                <SubmitButton
                                    onClick={() => handleDelete()}
                                >
                                    Löschen
                                </SubmitButton>
                            </>
                            :
                            <SubmitButton
                                disabled={!form.fieldsValid}
                                onClick={() => handleCreate()}
                            >
                                Erstellen
                            </SubmitButton>
                    }
                </ModalFooter>
            </Spacer>
        </Modal>
    )
}
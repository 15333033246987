import Button from "components/Button"
import Row from "components/Row"
import Spacer from "components/Spacer/Spacer"
import Tile from "components/Tiles/Tile"
import MonthSelect from "components/Time/MonthSelect"
import YearSelect from "components/Time/YearSelect"
import moment from "moment"
import { useCallback, useState } from "react"
import { useLocation, useNavigate, useOutletContext } from "react-router-dom"
import Table from "components/table/table"
import { useOperationReportColumns } from "./useOperationReportColumns"
import useOperationReportActions from "./useOperationReportActions"
import { useGeneratedOperationReport } from "./useOperationReport"
import OperationReportEntry from "./Entries/OperationReportEntry"
import { FaArrowLeft, FaTrash } from "react-icons/fa"
import { PersonOutletContextType } from "Persons/PersonOutlet"


const OperationReportCreateView = () => {

    const { person } = useOutletContext<PersonOutletContextType>()
    const [year, setYear] = useState(moment().year())
    const [month, setMonth] = useState(parseInt(moment().format('M')))
    const navigate = useNavigate()
    const report = useGeneratedOperationReport(person?.id, year, month)
    const reportActions = useOperationReportActions()
    const { search } = useLocation()

    const addEntry = () => {
        report.entries.add(new OperationReportEntry(report.id, null, null, null, 0, 0))
    }

    const removeEntry = useCallback((entry) => {
        report.entries.remove(entry) 
    }, [report.entries])

    const onEntriesChanged = (entries) => {
        report.entries.setData(entries)
    }

    const entryColumns = useOperationReportColumns([
        {
            Icon: FaTrash,
            onClick: removeEntry
        }
    ])


    return (
        <>
            <Spacer medium>
                <Tile 
                    title='Stundenbeleg generieren'
                    leftActions={[
                        {
                            icon: 'fa/FaArrowLeft',
                            execute: () => navigate(-1)
                        }
                    ]}
                >
                    <Spacer hMedium>
                        <Row vCentered>
                            <YearSelect
                                label='Jahr'
                                name='year'
                                value={year}
                                onChange={(event) => setYear(event.target.value)}
                            />
                            <MonthSelect
                                label='Monat'
                                name='month'
                                value={month}
                                onChange={(event) => setMonth(event.target.value)}
                            />
                            <Button onClick={addEntry}>Eintrag hinzufügen</Button>
                        </Row>
                    </Spacer>
                </Tile>
            </Spacer>
            <Spacer medium vTiny shrink>
                <Tile>
                    <Table
                        onChange={onEntriesChanged}
                        data={report.entries.data} 
                        columns={entryColumns}
                    />
                </Tile>
            </Spacer>
            <Spacer medium>
                <Row>
                    <Button 
                        disabled={!report || !report.isValid} 
                        onClick={() => reportActions.dispatch('create', report.toDatabase()).then(() => navigate(-1))}
                    >
                        Erstellen
                    </Button>
                </Row>
            </Spacer>
        </>
    )
}

export default OperationReportCreateView
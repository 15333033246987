import styled from 'styled-components'
import { Size } from 'Theming/Theme'

type PaddingProps = {
    vertical?: Size,
    horizontal?: Size,
    top?: Size,
    bottom?: Size,
    left?: Size,
    right?: Size
}

const Padding = styled.div<PaddingProps>`
    padding: ${props => {
        const fallbackSize:Size = 'medium'
        const top = props.top || props.vertical || fallbackSize
        const bottom = props.bottom || props.vertical || fallbackSize
        const left = props.left || props.horizontal || fallbackSize
        const right = props.top || props.horizontal || fallbackSize
        return props.theme.getSpacing(top, right, bottom, left)
    }};
`

export default Padding
import Table from 'components/table/table';
import { useInput } from 'hooks/input';
import moment from 'moment';
import { useAbsenceTableColumns } from './AbsenceTableColumns';
import Spacer from 'components/Spacer/Spacer';
import Row from 'components/Row';
import TextInput from '../components/input/TextInput';
import DatePicker from 'components/input/DatePicker';
import { useState, useMemo, useContext } from 'react';
import Tile from 'components/Tiles/Tile';
import { ModalContext } from 'components/modal/ModalContext';
import { useAbsences } from './AbsenceApi';

export default function AbsenceView() {

    const [date, setDate] = useState(moment().format('YYYY-MM-DD'))
    const { value: filter, bind: bindFilter } = useInput("");
    const { columns } = useAbsenceTableColumns();

    const absenceParams = useMemo(() => {
        return { start: date }
    }, [date])

    const { data: absences } = useAbsences(absenceParams)
    const { absenceModal } = useContext(ModalContext)

    return (
        <Spacer medium shrink>
            <Tile 
                title="Abwesenheiten"
                actions={[
                    {
                        icon: 'fa/FaPlus',
                        execute: () => absenceModal.select({ 
                            'begin_date': moment().format('YYYY-MM-DD'), 
                            'request_date': moment().format('YYYY-MM-DD') 
                        })              
                    }
                ]}
            >
                <Spacer hMedium grow>
                    <Row vCentered>
                        <TextInput size={20} label='Filtern' type='text' {...bindFilter} />
                        <DatePicker label='Datum' value={date} onChange={(event) => { setDate(event.value || null) }} />
                    </Row>
                </Spacer>
                <Table
                    columns={columns}
                    data={absences}
                    filter={filter}
                    onClick={(absence) => absenceModal.select(absence)}>
                </Table>
            </Tile>
        </Spacer>
    )
}
import { formatPeriodString } from "Time/timeUtility";
import moment from "moment";


const eventPassed = (event, date=moment()) => {
    if(!event) {
        return false
    }

    return (
        event.start ? date.isAfter(event.start) :
            event.date ? date.isAfter(event.date) :
                true
    )
}

const includesDate = (event, date) => {
    if(!event || !date) {
        return false
    }

    return moment(date).isSame(event.date, 'date')
}

const hasValidTimeframe = (event) => {
    if(!event) {
        return false
    }

    var start = moment(event.start)
    var end = moment(event.end)

    return start.isSameOrBefore(end)
}

const getDateArray = (event) => {
    if(!event || !hasValidTimeframe(event)) {
        return []
    }

    var start = moment(event.start).startOf('day')
    var end = moment(event.end).startOf('day')
    var dates = []

    for (var date = start; end.diff(date, 'days') >= 0; date.add(1, 'day')) {
        dates.push(date.format('YYYY-MM-DD'))
    }
    return dates
}

const isOperation = (event) => {
    return event?.customer_id != null
}

const getStart = (event) => {
    if (!event) {
        return null
    }
    var time = event.time || '23:59'
    return moment(`${event.date} ${time}`)
}

const started = (event, date = moment()) => {
    if (!event || !event.date) {
        return false
    }

    return getStart(event).isSameOrBefore(date)
}

const confirmationRequired = (event) => {
    if (event.absent || event.confirmed != null) {
        return false
    }
    return isOperation(event) && started(event)
}

const getPeriodLabel = (event, format='DD.MM.YYYY HH:mm') => {
    var start = moment(event.date)
    var end = null


    if (event.time) {
        start = moment(`${event.date} ${event.time}`, 'YYYY-MM-DD HH:mm')

        if (event.duration) {
            end = start.clone().add(event.duration, 'seconds')
            return formatPeriodString(start, end, null, format)
        }
    }
    else {
        format = format.replaceAll('H', '')
        format = format.replaceAll('m', '')
        format = format.replaceAll(':', '')
        format = format.replaceAll(',', '')
    }

    var array = []
    if (format !== '') {
        array.push(start.format(format))
    }
    if (event.duration) {
        array.push(`${parseFloat(event.duration / 3600).toFixed(1)} Stunden`)
    }

    return array.join(' ')
}

const getHourCost = (event) => {
    if(!event) {
        return 0
    }
    return event.hours * event.hourly_wage
}

const getKilometerCost = (event) => {
    if (!event) {
        return 0
    }
    return event.kilometer * event.kilometer_cost
}

const getCost = (event) => {
    return getHourCost(event) + getKilometerCost(event)
}

const hasEmployee = (event) => {
    if(!event) {
        return false
    }
    return event.employee_id && !event.absent
}

const isUnclear = (event) => {
    if(!event) {
        return false
    }
    return (event.employee_id === null || event.absent) && event.confirmed == null
}

const displayForEmployee = (event, employeeId) => {
    if(!event) {
        return false
    }
    return (event?.employee_id === null && event?.creator_id === employeeId) || event?.employee_id === employeeId
}

const isSubstituted = (event) => {
    if(!event || event.employee_id === null) {
        return false
    }
    return event.creator_id != event.employee_id
}


export default {
    eventPassed,
    includesDate,
    hasValidTimeframe,
    getDateArray,
    getPeriodLabel,
    getHourCost,
    getKilometerCost,
    getCost,
    isOperation,
    started,
    getStart,
    confirmationRequired,
    isUnclear,
    isSubstituted,
    displayForEmployee,
    hasEmployee
}

import ListView from 'components/ListView/ListView'
import Padding from 'components/Padding'
import Tile from 'components/Tiles/Tile'
import moment from 'moment'
import { usePersons } from 'Persons/PersonApi'
import PersonListTile from 'Persons/PersonListTile'
import { useEffect, useMemo, useRef, useState } from 'react'
import styled from 'styled-components'
import tt from '@tomtom-international/web-sdk-maps';
import useMarkers from 'components/map/markers'
import PersonListItem from 'Persons/PersonListItem'

const Layout = styled.div`
    flex-grow: 1;
    display: grid;
    grid-template-columns: minmax(min-content, 30em) 1fr;
    grid-template-rows: auto 1fr 1fr;
    grid-template-areas:
        "list-header list-header"
        "list map"
        "list map";

    row-gap: ${props => props.theme.spacing.hMedium};
    padding: ${props => props.theme.spacing.vMedium} ${props=> props.theme.spacing.hMedium};
`

const ListHeaderArea = styled.div`
    grid-area: list-header;
`

const ListArea = styled.div`
    grid-area: list;
    overflow: auto;
    direction: rtl;
`

const MapArea = styled.div`
    grid-area: map;
`

export default function PersonView() {

    const mapElement = useRef();
    const [map, setMap] = useState(null);

    const params = useMemo(() => ({
        periodStart: moment().format('YYYY-MM-DD'),
        periodEnd: moment().format('YYYY-MM-DD')
    }), [])

    const persons = usePersons(params)

     useMarkers(
         persons.data,
        map,
        {
            markerClass: 'employee-marker',
            accessor: (person) => [person.address?.longitude || 0, person.address?.latitude || 0]
        }
    );

     useEffect(() => {
            let map = tt.map({
                key: process.env.REACT_APP_TOM_TOM_API_KEY,
                container: mapElement.current,
                center: [7.2, 51.5],
                zoom: 10,
                language: 'DE'
            });
    
            setMap(map);
    
            return () => {
                map.remove()
            };
        }, [setMap, mapElement]);

    return (
        <Layout>
            <ListHeaderArea>
                <Tile></Tile>
            </ListHeaderArea>
            <ListArea>
                <ListView
                    data={persons.data}
                    listItem={(person) => (
                        <PersonListItem
                            person={person}  
                        />
                    )}
                    Separator={() => null}
                />
            </ListArea>
            <MapArea ref={mapElement}/>
        </Layout>
    )
}
import UpcomingAbsencesTile from 'Absences/UpcomingAbsencesTile'
import SubstitutedEventsTile from 'Events/SubstitutedEventsTile'
import UpcomingBirthdaysTile from 'Persons/Birthdays/UpcomingBirthdaysTile'
import styled from 'styled-components'
import TodoSidebar from './TodoSidebar'

const Layout = styled.div`
    display: flex;
    flex-direction: row;
    flex-grow: 1;
`

const NewsSection = styled.div`
    display: flex;
    flex-direction: column;
    flex-grow: 1;
    padding: ${props => props.theme.spacing.vMedium} ${props => props.theme.spacing.hMedium};
    gap: ${props => props.theme.spacing.vLarge};
`

const NewsTiles = styled.div`
    display: flex;
    column-gap: ${props => props.theme.spacing.hMedium};
    row-gap: ${props => props.theme.spacing.vMedium};
    flex-wrap: wrap;

    > * {
        flex: 1;
    }
`

const SubstituteWrapper = styled.div`
    min-width: 30em;
`

const AbsenceTileWrapper = styled.div`
    min-width: 23em;
`

const TodoSection = styled.div`
    display: flex;
    flex-direction: column;
    gap: ${props => props.theme.spacing.vLarge};
    min-width: 28em;
`

export default function Home() {

    return (
        <Layout>
            <NewsSection>
                <NewsTiles>
                    <UpcomingBirthdaysTile/>
                    <UpcomingAbsencesTile/>
                    <SubstitutedEventsTile/>
                </NewsTiles>
            </NewsSection>
            <TodoSection>
                <TodoSidebar/>
            </TodoSection>
        </Layout>
    )
}
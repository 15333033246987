import { useMatch, useNavigate, useResolvedPath } from "react-router-dom"
import SidebarItem, { SidebarItemProps } from "./SidebarItem"
import Theme from "Theming/Theme"

type SidebarNavItemProps = SidebarItemProps & {
    to:string
}

export default function SidebarNavItem({ to, ...props}:SidebarNavItemProps) {

    const navigate = useNavigate()
    const resolved = useResolvedPath(to)
    const match = useMatch({ path: resolved.pathname, end: true})

    return (
        <SidebarItem
            onClick={() => navigate(to)}
            bannerColor={match ? Theme.colors.primary : 'transparent'}
            backgroundColor={match ? '#f4f4f4': 'transparent'}
            {...props}
        />
    )
}
import Spacer from "components/Spacer/Spacer";
import Tile from "components/Tiles/Tile";
import useModal from "components/modal/useModal";
import Employee from "./Employee";
import TileSection from "components/TileSection";
import Label from "components/labels/Label";
import { useMemo } from "react";
import Row from "components/Row";
import { localizeDateString } from "Time/timeUtility";
import { useAssignments } from "Assignments/AssignmentApi";
import moment from "moment";
import { formatFloat } from "utils/string-utility";
import CurrencyLabel from "components/labels/currency-label";
import Column from "components/Column";
import EmployeeModal from "./EmployeeModal";
import TaxBracketLabel from "TaxBrackets/TaxBracketLabel";

interface Props {
    employee: Employee
}

const EmployeeTile = ({ employee } : Props) => {

    const modal = useModal<Employee>()   

    const assignmentParams = useMemo(() => {
        return {
            employee_id: employee?.person_id,
            start_date: moment().format('YYYY-MM-DD')
        }
    }, [employee])

    const assignments = useAssignments(assignmentParams)

    const assigned_hours = useMemo(() => {
        return assignments.data?.reduce((prev, curr) => prev + curr.monthly_hours, 0)
    }, [assignments])

    const tasks = useMemo(() => {
        if(!employee) {
            return ''
        }

        const array = [
            ...employee.housekeeping ? ['Hauswirtschaft'] : [],
            ...employee.home_care ? ['Betreuung'] : [],
            ...employee.dementia ? ['Demenz'] : []
        ]

        return array.length > 0 ? array.join(', ') : "-"
    }, [employee])

    const tags = useMemo(() => {
        var result = []
        if (employee?.state === null) {
            result.push({
                text: 'Bewerber'
            })
        }
        if (employee?.state === 0) {
            result.push({
                text: 'Ehemalig'
            })
        }
        return result
    }, [employee])

    if(!employee) {
        return null
    }

    return (
        <>
            <EmployeeModal {...modal} isUpdate={true}/>
            <Tile
                title='Mitarbeiterdaten'
                tags={tags}
                actions={[
                    {
                        icon: 'fa/FaPen',
                        execute:() => modal.select(employee)
                    }
                ]}
            >
                <Spacer hMedium>
                    <Column gap='1em'>
                        <div>
                            <TileSection label='Tätigkeit' visible={Boolean(employee.work_field_label)}>
                                <Label>{employee.work_field_label}</Label>
                            </TileSection>
                            <TileSection label='Aufgabenfelder' visible={employee.home_care || employee.housekeeping || employee.dementia}>
                                <Label>{tasks}</Label>
                            </TileSection>
                            <TileSection label='Gelernt. Beruf' visible={Boolean(employee.occupation)}>
                                <Label>{employee.occupation}</Label>
                            </TileSection>
                            <TileSection label='Pflegeausbildung'>
                                <Label>{employee.nursing_training ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                            <TileSection label='Nebenhob'>
                                <Label>{employee.sideline_job ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                            <TileSection label='Minijob'>
                                <Label>{employee.minijob ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                            <TileSection label='Monatl. Stunden' visible={Boolean(employee.monthly_hours)}>
                                <Label>
                                    {`${formatFloat(assigned_hours)} / ${formatFloat(employee.monthly_hours, ' Std.')}`}
                                </Label>
                            </TileSection>
                            <TileSection label='Einsatzradius'>
                                <Label>{formatFloat(employee.operation_radius, ' km', 'Flexibel')}</Label>
                            </TileSection>
                            <TileSection label='Stundenlohn' visible={Boolean(employee.hourly_wage)}>
                                <CurrencyLabel value={employee.hourly_wage} />
                            </TileSection>
                        </div>
                        <div>
                            <TileSection label='Führerschein'>
                                <Label>{employee.driving_license ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                            <TileSection label='Auto'>
                                <Label>{employee.car ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                            <TileSection label='Führungszeugnis'>
                                <Label>{employee.clearance_certificate ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                        </div>
                        <div>
                            <TileSection label='SV-Nr.' visible={Boolean(employee.social_insurance_number)}>
                                <Label>{employee.social_insurance_number}</Label>
                            </TileSection>
                            <TileSection label='Steuerklasse' visible={Boolean(employee.tax_bracket_id)}>
                                <TaxBracketLabel id={employee.tax_bracket_id} />
                            </TileSection>
                            <TileSection label='Steuernummer' visible={Boolean(employee.tax_number)}>
                                <Label>{employee.tax_number}</Label>
                            </TileSection>
                            <TileSection label='Elterneigenschaft'>
                                <Label>{employee.parenthood ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                            <TileSection label='Kinderfreibetrag' visible={Boolean(employee.parenthood) && Boolean(employee.child_allowance)}>
                                <Label>{employee.child_allowance}</Label>
                            </TileSection>
                            <TileSection label='RV-Pflicht'>
                                <Label>{employee.annuity_insurance ? 'Ja' : 'Nein'}</Label>
                            </TileSection>
                            <TileSection label='Befristet' visible={employee.determinable}>
                                {
                                    <Row>
                                        <Label>Ja</Label>
                                        {
                                            employee.determinable_till ? (
                                                <Label subtle>{`(Bis ${localizeDateString(employee.determinable_till, '-')})`}</Label>
                                            ) : null
                                        }
                                    </Row>
                                }
                            </TileSection>    
                        </div>
                    </Column>
                </Spacer>
            </Tile>
        </>
    )
}

export default EmployeeTile

import styled from 'styled-components'
import { Tag, TagProps } from './Tag'

const Container = styled.div`
    display: flex;
    flex-direction: row;
    column-gap: 0.15em;
    row-gap: 0.15em;
    flex-wrap: wrap;
`


export interface TagContainerProps {
    children?: React.ReactNode,
    tags?: TagProps[]
}

export default function TagContainer({ children, tags }: TagContainerProps) {

    if(!children && (!tags || tags.length === 0)) {
        return null
    }


    return (
        <Container>
            { children }
            { tags?.map((tagProps, index) => (
                <Tag key={index} {...tagProps}/>
            ))}
        </Container>
    )
}
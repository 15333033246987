import styled from 'styled-components'
import Theme from 'Theming/Theme'

const Container = styled.span`
    border-radius: 8px;
    color: ${props => props.theme.colors[props.color] || '#555'};
    background-color: ${props => props.theme.colors[props.backgroundColor] || '#dadada'};
    padding: 0.25em 1em;
    font-size: 0.75em;
    height: 19.2px;
    display: inline-flex;
    align-items: center;
    justify-content: center;
`

const Text = styled.div`
    font-size: 12px;
    white-space: nowrap;
`

export type TagProps = {
    text: string,
    color?: string,
    backgroundColor?: string
}

const Tag = ({ text, ...props}:TagProps) => {

    return (
        <Container {...props}>
            <Text>{text}</Text>
        </Container>
    )
}

const SecondaryTag = (props) => {
    
    return (
        <Tag 
            color={Theme.whiteColor} 
            backgroundColor={Theme.secondaryColor} 
            {...props}
        />
    )
}


export {
    Tag,
    SecondaryTag
}
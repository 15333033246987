import moment from "moment";
import { useMemo, useReducer, useState } from "react";
import { EventProvider } from "Events/EventContext";
import { AbsenceProvider } from "Absences/AbsenceContext";
import EmployeeSchedule from "./EmployeeSchedule";
import Tile from "components/Tiles/Tile";
import Spacer from "components/Spacer/Spacer";
import { EmployeeProvider } from "Employees/EmployeeContext";
import ScrollView from "components/ScrollView";
import ScheduleDetails from "Schedules/ScheduleDetails";
import { CustomerProvider } from "Customer/CustomerContext";


const reducer = (state, action) => {
    switch (action.type) {
        case 'show':
            return {
                employeeId: action.employeeId,
                date: action.date,
                visible: true
            }
        case 'hide':
            return {
                ...state,
                visible: false
            }
        default:
            return state
    }
}

const EmployeeManagementOverview = () => {

    const [week, setWeek] = useState(new Date())
    const [state, dispatch] = useReducer(reducer, {
        employeeId: null,
        visible: false,
        date: null
    })


    const showDetails = (employeeId, date) => {
        dispatch({
            type: 'show',
            employeeId,
            date
        })
    }

    const hideDetails = () => {
        dispatch({
            type: 'hide'
        })
    }

    const eventParams = useMemo(() => ({
        periodStart: moment(week).startOf('week').format('YYYY-MM-DD HH:mm'),
        periodEnd: moment(week).endOf('week').format('YYYY-MM-DD HH:mm')
    }), [week])

    const employeeParams = useMemo(() => ({
        is_employee: true
    }), [])

    const customerParams = useMemo(() => ({
        is_customer: true
    }), [])


    const absenceParams = useMemo(() => ({
        start: moment(week).startOf('week').format('YYYY-MM-DD'),
        end: moment(week).endOf('week').format('YYYY-MM-DD')
    }), [week])

    const onCellClicked = ({ employeeId, date }) => {
        if (!state.visible) {
            showDetails(employeeId, date)
        }
        else {
            hideDetails()
        }
    }

    return (
        <CustomerProvider params={customerParams}>
            <EventProvider params={eventParams}>
                <EmployeeProvider params={employeeParams}>
                    <AbsenceProvider params={absenceParams}>
                        <ScheduleDetails
                            date={state.date}
                            employeeId={state.employeeId}
                            visible={state.visible}
                            hide={hideDetails}
                        />
                        <ScrollView>
                            <Spacer medium>
                                <Tile>
                                    <EmployeeSchedule
                                        week={week}
                                        setWeek={setWeek}
                                        onClick={onCellClicked}
                                    />
                                </Tile>
                            </Spacer>
                        </ScrollView>
                    </AbsenceProvider>
                </EmployeeProvider>
            </EventProvider>
        </CustomerProvider>
    )
}


export default EmployeeManagementOverview
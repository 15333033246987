import Label from "components/labels/Label";
import Screen from "../Screen";
import ScreenLayout from "../ScreenLayout";
import SideBar from "Sidebar/SideBar";
import Page from "components/Page";
import { Outlet } from "react-router-dom";
import { useMemo } from "react";
import SidebarNavigation from "Sidebar/SidebarNavigation";
import Spacer from "components/Spacer/Spacer";


export default function DataScreen() {

    const routes = useMemo(() => ([
        {
            label: "Adressen",
            to:''
        },
        {
            label: 'Dienstleistungen',
            to: 'Dienstleistungen'
        }
    ]), [])

    return (
        <Screen>
            <ScreenLayout.SideBar>
                <SideBar>
                    <SidebarNavigation routes={routes}/>
                </SideBar>
                <Page>
                    <Spacer medium shrink>
                        <Outlet/>
                    </Spacer>
                </Page>
            </ScreenLayout.SideBar>
        </Screen>
    )
}
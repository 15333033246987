import Theme from "Theming/Theme"
import { formatPeriodString } from "Time/timeUtility"
import ListItem from "components/ListView/BetterListItem"

const AbsenceListItem = ({absence, ...props}) => {
    return (
        <ListItem 
            bannerColor={Theme.subtleColor}
            title={absence.type_label}
            subtitle={formatPeriodString(absence.begin_date, absence.end_date)}
            {...props}
        />
    )
}

export default AbsenceListItem
import ClickableOpacity from 'components/Decorator/ClickableOpacity';
import { Text } from 'components/labels/Label';
import ListView from 'components/ListView/ListView';
import { ModalContext } from 'components/modal/ModalContext';
import Padding from 'components/Padding';
import Row from 'components/Row';
import ScrollView from 'components/ScrollView';
import { useEvents } from 'Events/EventApi';
import EventUtility from 'Events/EventUtility';
import moment from 'moment';
import PersonTag from 'Persons/PersonLink';
import { useContext, useMemo } from 'react';
import styled from 'styled-components';
import TagContainer from 'Tags/TagContainer';
import { formatPeriodString } from 'Time/timeUtility';
import { useVacationRequests } from 'Vacations/VacationApi';

const Container = styled.div`
    background-color: ${props => props.theme.colors.white};
    flex-grow: 1;
    overflow: hidden;
    display: flex;
    flex-direction: column;
`

const ADVERTISED_EVENT_PARAMS = {
    periodStart: moment().format('YYYY-MM-DD HH:mm:ss'),
    periodEnd: moment().add(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
    advertised: true,
    includeOptions: true
}

const UNACCOUNTED_EVENT_PARAMS = {
    periodStart: moment().format('YYYY-MM-DD HH:mm:ss'),
    periodEnd: moment().add(7, 'days').format('YYYY-MM-DD HH:mm:ss'),
    unaccounted: true,
    includeOptions: true
}

const VACATION_REQUEST_PARAMS = {}

type TodoItemProps = {
    date:Date,
    Component:  React.ReactNode
}

export default function TodoSidebar() {

    const { vacationRequestModal, eventModal } = useContext(ModalContext)

    const advertisedEvents = useEvents(ADVERTISED_EVENT_PARAMS)
    const unaccountedEvents = useEvents(UNACCOUNTED_EVENT_PARAMS)
    const vacationRequests = useVacationRequests(VACATION_REQUEST_PARAMS)

    const listData:TodoItemProps[] = useMemo(() => {
        const result = []

        unaccountedEvents.data?.forEach(event => {
            result.push({
                date: new Date(event.start),
                Component: (
                    <ClickableOpacity onClick={() => eventModal.select(event)}>
                        <Padding>
                            <Text>Vertretungsbedarf klären</Text>
                            <Text color='subtleText' size='small'>{EventUtility.getPeriodLabel(event, 'ddd DD.MM HH:mm')}</Text>
                            <Row gap='medium'>
                                <TagContainer>
                                    <PersonTag id={event.customer_id} />
                                    <PersonTag id={event.employee_id} />
                                </TagContainer>
                            </Row>
                        </Padding>
                    </ClickableOpacity>
                )
            })
        })

        advertisedEvents.data?.forEach(event => {
            result.push({
                date: new Date(event.start),
                Component: (
                    <ClickableOpacity onClick={() => eventModal.select(event)}>
                        <Padding>
                            <Text>Vertretung finden</Text>
                            <Text color='subtleText' size='small'>{EventUtility.getPeriodLabel(event, 'ddd DD.MM HH:mm')}</Text>
                            <Row gap='medium'>
                                <TagContainer>
                                    <PersonTag id={event.customer_id} />
                                    <PersonTag id={event.employee_id} />
                                </TagContainer>
                            </Row>
                        </Padding>
                    </ClickableOpacity>
                )
            })
        })

        vacationRequests.data?.forEach(vacationRequest => {
            result.push({
                date: new Date(vacationRequest.request_date),
                Component: (
                    <ClickableOpacity onClick={() => vacationRequestModal.select(vacationRequest)}>
                        <Padding>
                            <Text>Urlaubsantrag</Text>
                            <Text size='small' color='subtleText'>{formatPeriodString(vacationRequest.start_date, vacationRequest.end_date)}</Text>
                            <TagContainer>
                                <PersonTag id={vacationRequest.employee_id}/>
                            </TagContainer>
                        </Padding>
                    </ClickableOpacity>
                )
            })
        })

        result.sort((a, b) => a.date.valueOf() - b.date.valueOf())

        return result
    }, [unaccountedEvents.data, advertisedEvents.data, vacationRequests.data])

    return (
        <Container>
            <Padding>
                <Text size='large' bold>Zu erledigen</Text>
            </Padding>
            <ScrollView>
                <Padding left='none' vertical='none'>
                    <ListView
                        data={listData}
                        Separator={() => null}
                        listItem={(item) => item.Component}
                    />
                </Padding>
            </ScrollView>
        </Container>
    )
}
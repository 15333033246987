import Column from "components/Column"
import ConditionalView from "components/Decorator/ConditionalView";
import ListView from "components/ListView/ListView"
import Label from "components/labels/Label"
import styled from 'styled-components';

const BookingLabel = styled(Label)`
    :before {
        content:"• ";
        color: #474747;
    }
`

const VacationBookingPreview = ({preview}) => {

    return (
        <Column gap='1em' width='18em'>
            <Label bold>Buchungsvorschau</Label>
            {
                preview?.bookings?.length > 0 ? (
                    <Column gap='0.25em'>
                        <ListView
                            data={preview.bookings}
                            listItem={(booking) => <BookingLabel small>{`${booking.days} Tage für ${booking.charged_year}`}</BookingLabel>}
                            Separator={() => null}
                        />
                        <ConditionalView condition={preview.unbookedDays > 0}>
                            <Label small danger>{`${Math.round(preview.unbookedDays)} Tage können nicht verbucht werden`}</Label>
                        </ConditionalView>
                    </Column>
                ) : (
                    <Label small subtle>Es werden keine Buchungen vorgenommen</Label>
                )
            }
        </Column>
    )
}

export default VacationBookingPreview
import { useQuery } from "@tanstack/react-query";
import { useApiContext } from "Common/Api/ApiContext";

const useVacationBookingPreview = (params) => {

    const { axiosInstance } = useApiContext()

    return useQuery({
        queryKey: ['vacation_bookings', 'preview_period', params],
        queryFn: () => axiosInstance.get('vacation_bookings/preview_period', {params}).then(res => res.data)
    })

}

export default useVacationBookingPreview
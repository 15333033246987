import { useMemo } from "react"
import * as Fontawesome from "react-icons/fa"
import Theme from "Theming/Theme"

export interface IconProps {
    path?:string
    color?:string,
    size?:string
}

export default function Icon({ 
    path,
    color=Theme.colors.text,
    size='16px'
}:IconProps) {

    const [library, key] = useMemo(() => {
        
        return typeof path === 'string' ? path.split('/') : [undefined, undefined]
    }, [path])

    if(!path) {
        return null
    }

    if(library === 'fa' && key in Fontawesome) {
        const Icon = Fontawesome[key]
        return (
            <Icon color={color} size={size}/>
        )
    }

    return (
        <Fontawesome.FaQuestion/>
    )
}